/**
 *  Import node modules
 */
import { combineReducers } from 'redux-immutable';
/**
 *  Import reducers
 *  All reducers used in the app must be declared here!
 */
import authReducer from './modules/auth/reducer';
import jobsReducer from './modules/jobs/reducer';
import postJobsReducer from './modules/jobPost/reducer';
import postTrainingsReducer from './modules/trainingPost/reducer';
import trainingsReducer from './modules/trainings/reducer';
import trainingsAnylasisReducer from './modules/trainingAnylasis/reducer';
import resourcesReducer from './modules/resources/reducer';
import postResourcesReducer from './modules/postResources/reducer';
import imageCroppingModalReducer from './modules/imageCroppingModal/reducer';
import userProfile from './modules/userProfile/reducer';
import pollingReducer from './modules/polling/reducer';
import newsReducer from './modules/news/reducer';
import sideBarReducer from './modules/sidebar/reducer';
import postNewsReducer from './modules/newsPost/reducer';
import reportsReducer from './modules/reports/reducer';
import postEventsReducer from './modules/eventPost/reducer';
import reflectiveAccountReducer from './modules/revalidation/reflectiveAccount/reducer';
import reflectiveDiscussionReducer from './modules/revalidation/reflectiveDiscussion/reducer';
import feedbackLogReducer from './modules/revalidation/feedbackLog/reducer';
import cpdLogReducer from './modules/revalidation/cpdLog/reducer';
import practiceLogReducer from './modules/revalidation/practiceLog/reducer';
import competenciesReducer from './modules/competencies/reducer';
import postWellBeingReducer from './modules/wellBeingPost/reducer';
import wellBeingReducer from './modules/wellBeings/reducer';
import postPolicyDocReducer from './modules/policyDocPost/reducer';
import policyDocsReducer from './modules/policyDocs/reducer';
import forumsReducer from './modules/forums/reducer';
import teamReducer from './modules/teams/reducer';
import commentsReducer from './modules/comments/reducer';
import trainingsAnalysisReportReducer from './modules/trainingAnalysisReport/reducer';
import appraisalsReducer from './modules/appraisals/reducer';

// Admin User listing
import adminUserListingReducer from './modules/adminUserListing/reducer';
import addUserReducer from './modules/adminAddUser/reducer';
import postInvoicesReducer from './modules/postInvoices/reducer';
import postPlacementsReducer from './modules/placementPost/reducer';
import placementsReducer from './modules/placements/reducer';
import calenderReducer from './modules/calender/reducer';
import eventReducer from './modules/events/reducer';
import pcnPracticesReducer from './modules/myPcn/reducer';
import updatePracticeReducer from './modules/updatePersonalInfo/reducer';

/**
 *  Combine the reducers
 */
const reducers = combineReducers({
	auth: authReducer,
	appraisals: appraisalsReducer,
    jobs: jobsReducer,
    postJobs: postJobsReducer,
    postTrainings: postTrainingsReducer,
    trainings: trainingsReducer,
    trainingForm: trainingsAnylasisReducer,
    analysisReport: trainingsAnalysisReportReducer,
    news: newsReducer,
    calender: calenderReducer,
    postNews: postNewsReducer,
    postPlacements: postPlacementsReducer,
    placements: placementsReducer,
    postInvoices: postInvoicesReducer,
    resources: resourcesReducer,
    postResources: postResourcesReducer,
    // myPcn: myPcnReducer,
    imageCropping: imageCroppingModalReducer,
    sidebar: sideBarReducer,
    adminUserListing: adminUserListingReducer,
    addUser: addUserReducer,
    reports: reportsReducer,
    events: eventReducer,
    postEvents: postEventsReducer,
    reflectiveAccount: reflectiveAccountReducer,
    reflectiveDiscussion: reflectiveDiscussionReducer,
    feedbackLog: feedbackLogReducer,
    cpdLog: cpdLogReducer,
    practiceLog: practiceLogReducer,
    competencies: competenciesReducer,
    postWellBeing: postWellBeingReducer,
    wellBeing: wellBeingReducer,
    postPolicyDoc: postPolicyDocReducer,
    policyDocs: policyDocsReducer,
    pcnPractices: pcnPracticesReducer,
    forums: forumsReducer,
    teams: teamReducer,
    comments: commentsReducer,
    userProfile,
    polling: pollingReducer,
    practice: updatePracticeReducer,
});

/**
 *  Export the combined reducers
 */
export default reducers;