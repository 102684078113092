import React from "react";
import { func, bool, number, object, array } from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { withRouter, Link } from "react-router-dom";
import UserHeader from 'components/Headers/UserHeader';
import PostForums from 'components/postForums';
import ReactPaginate from 'react-paginate';
import UserFooter from 'components/Footers/UserFooter';
import Poll from 'components/poll';
import Swiper from 'react-id-swiper';

import {getCategories, getPosts, getMyPosts, applyFilters, applyPagination, resetFilters, getPolls, addVoteToPoll} from '../../modules/forums/actions';
import {getCategoriesList, getPostsList, getIsFetchingPosts, 
        getIsFetchingCategories, getPage, getTotalPages,
        getIsFetchingMyPosts,
        getMyPostsList,
        getPollList,
        getIsFetchingPollingList,
      } from '../../modules/forums/selectors';

import Loader from 'components/Loader';

const pollsSliderParams = {
  autoHeight: true,
  slidesPerView: 1,
  navigation: {
    nextEl: '#poll-nav-next',
    prevEl: '#poll-nav-prev',
  },
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 1,
    },
    1199: {
      slidesPerView: 1,
    },
  }
}

class LSForum extends React.Component {
  state={
    search: '',
    swiper: null,
    isVisiblePostForums: false,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.catId !== this.props.match.params.catId) {
      // this.setState({ user: null });
      // fetchUserData(this.props.match.params.id)
      //   .then((user) => {
      //     this.setState({ user: user });
      //   })
      this.props.getMyPosts();
      this.props.getCategories();
      if (Object.keys(this.props.match.params).length > 0) {
        this.props.getPosts(this.props.match.params.catId).then(() => {
        }, () => {
          const { history } = this.props;
          if (history) {
            history.push('/user/ls-forums');
          }
        });
      } else {
        this.props.getPosts();
      }
    }
  }

  componentWillMount() {
    this.props.getMyPosts();
    this.props.getCategories();
    if (Object.keys(this.props.match.params).length > 0) {
      this.props.getPosts(this.props.match.params.catId).then(() => {
      }, () => {
        const { history } = this.props;
        if (history) {
          history.push('/user/ls-forums');
        }
      });
    } else {
      this.props.getPosts();
      this.props.getPolls();
    }
  }

  handleQueryChange = query => {
    this.setState({
      search: query
    });
  };

  searchPost = () => {
    const {search} = this.state;
    this.props.applyFilters(search);
  };

  resetSearch = () => {
    this.props.resetFilters();
  };

  getSingleCategory = (category, index, id) => (
    <Link to={`/user/ls-forums/cat/${id}`} className="px-4 link-hover d-flex align-items-center mb-4">
      <div className="thumb-wrap thumb-wrap-sm rounded-circle border ls-border-color">
        <img src={require("assets/images/forum/forum.png")} alt="thumb"  />
      </div>
      <h4 className="font-weight-normal mb-0 flex-fill font-18 family-poppins-medium text-truncate px-3">{category.name}</h4>
      <span className="btn lS-btn-icon lS-btn-icon-md lS-btn-blue-style">
      <span className="font-17">{category.posts_count ? category.posts_count : 0 }</span>
      </span>
    </Link>
  );

  getSinglePost = (post, index, id) => (
    <div className="card forum-card lS-card-shadow lS-card-radius border-0 mb-md-5 mb-3">
      <div className="card-body d-flex">
        <div className="thumb-wrap radius-10">
          <img src={require("assets/images/forum/forum.png")} alt="thumb" className="img-object-fit" />
        </div>
        <div className="flex-fill min-width0 pl-4 pt-3 d-flex flex-column justify-content-between">
          <div className="d-flex">
            <div className="flex-fill pr-4 min-width0">
              <h4 className="font-weight-normal family-poppins-medium">
                <Link to={`/user/ls-forums/post/${id}`} className="link-hover-text">
                  {post.title}
                </Link>
              </h4>
              <p className="color-grey font-18 text-truncate">{post.description ? post.description.substring(0,250)+ '...' : ``}</p>
            </div>
            <div className="mt-1">
              <span className="rating-count radius-10 py-1 px-3">{post.total_views ? post.total_views : 0} </span>
              <span className="d-block font-14 text-center mt-2">views</span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div className="font-18">
              <span className="d-block">{post.user_name}</span>
              <div className="color-grey">
                {/* {post.last_reply_duration ? post.last_reply_duration : `--`} 
                  <span className="mx-2">|</span> */}
                {post.total_replies ? post.total_replies : 0} comments</div>
            </div>
            <div className="font-18">
              <span className="cursor-pointer color-green mr-3"><i className="fas fa-share-alt" /></span>
              <span className="cursor-pointer color-gold mr-3"><i className="fas fa-bookmark" /></span>
              <span className="cursor-pointer color-grey"><i className="fas fa-ellipsis-h" /></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  getSingleMyPost = (post, index, id) => (
    <div className="px-4 link-hover d-flex align-items-center mb-4">
      <h4 className="font-weight-normal mb-0 flex-fill font-18 family-poppins-medium text-truncate pr-3">{post.title}</h4>
      <span className="btn lS-btn-icon lS-btn-icon-md lS-btn-blue-style">
        <span className="font-17">
          <Link to={`/user/ls-forums/post/${id}`} className="text-white"> 
            <i className="fas fa-eye" />
          </Link>  
        </span>
      </span>
    </div>
  );

  applyUserVote = (selectedOption, index, optionIndex) => {
    var param = {
      'poll_option_id': selectedOption.id,
    };
    this.props.addVoteToPoll(param, selectedOption.attributes.poll_id, index, optionIndex);
  };

  loadPage = page => {
    this.props.applyPagination(page);
  }

  pollsUpdateSlide = btnType => {
    const { swiper } = this.state;
    if (swiper !== null) {
      if (btnType === 'prev') {
        swiper.slidePrev();
        return;
      }

      swiper.slideNext();
    }
  };
  
  render() {
    const { categories, posts, myPosts, isFetchingPosts,  isFetchingCategories , isFetchingMyPosts, isFetchingPollingList, totalPages, page, pollList} = this.props;
    const { search, isVisiblePostForums } = this.state;
	return (
      <>
        <UserHeader />
        {/* Page content */}
        <main className="m-0 px-3 px-md-5 mb-5">
          <div className="container-fluid mt-md-5 mt-4 p-0">
            <div className="card border-0 page-title-bar">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="col-lg-8">
                    <h4 className="family-poppins-semibold m-0">Forums</h4>
                    <p className="mb-0 font-14 color-grey">Connect share and collaborate with your colleagues across your primary care networks.</p>
                  </div>
                  <div className="col-lg-4">
                    <div className="d-flex justify-content-lg-end justify-content-center">
                      <button type="button" className="mt-lg-0 mt-3 btn btn-lg btn-primary lS-btn-blue-large" onClick={() => this.setState({ isVisiblePostForums: true })}>
                        Start New Topic
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* detail tiles */}
            <div className="container-fluid p-0 mt-md-5 mt-4">
              <div className="mb-4">
                <span className="color-grey cursor-pointer"><i className="fas fa-grip-horizontal mr-3" /></span>
                <span className="color-grey active-sort cursor-pointer"><i className="fas fa-grip-lines mr-3" /></span>
                <span className="color-grey font-14">Sort By: Ascending <i className="fas fa-caret-down ml-1" /></span>
              </div>
              <div className="row mx-xl-n4">
                <div className="col-xl-8 px-xl-4">
                  {!isFetchingPosts && posts && posts.length > 0 && (
                    posts && posts.length > 0 && posts.map((post, index) => this.getSinglePost(post.attributes, index, post.id))
                  )}
                  
                  {!isFetchingPosts && posts && posts.length === 0 && (
                    <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                      No Posts found
                    </div>
                  )}

                  {isFetchingPosts && (
                    <Loader />
                  )}

                  <div aria-label="Page navigation example" className="ls-pagination">
                    <ReactPaginate
                        containerClassName={'pagination justify-content-end mt-5 mb-5'}
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        forcePage={page - 1}
                        onPageChange={selectedItem => {
                            if (isFetchingPosts) return;
                            this.loadPage(selectedItem.selected + 1);
                        }}
                        subContainerClassName={'pages pagination'}
                        pageClassName={'page-item'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        pageLinkClassName={'page-link'}
                        activeClassName={'active'}
                        />
                  </div>
                </div>
                <div className="col-xl-4 px-xl-4">
                  <div className="row flex-xl-column flex-md-row flex-column">
                    <div className="col-xl-12 col-md-6 order-1">
                      <div className="card aside-card lS-card-shadow lS-card-radius border-0 mb-md-5 mb-4 ">
                        <h4 className="family-poppins-semibold border-bottom ls-border-color p-4 mb-0">Top Categories</h4>
                        <div className="p-0 pt-3 card-body">
                          {isFetchingCategories && (
                            <Loader />
                          )}
                          {!isFetchingCategories && categories && categories.length === 0 && (
                            <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                              No Categories found
                            </div>
                          )}
                          {categories && categories.length > 0 && categories.map((category, index) => this.getSingleCategory(category.attributes, index, category.id))}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-md-6 order-xl-3 order-2">
                      <div className="card aside-card lS-card-shadow lS-card-radius border-0 mb-md-5 mb-4">
                        <h4 className="family-poppins-semibold border-bottom ls-border-color p-4 mb-0">My Threads</h4>
                        <div className="card-body p-0 pt-3">
                          {myPosts && myPosts.length > 0 && myPosts.map((myPost, index) => this.getSingleMyPost(myPost.attributes, index, myPost.id))}

                          {!isFetchingMyPosts && myPosts && myPosts.length === 0 && (
                            <div className="text-center bg-white py-5 px-3 radius-10 lS-card-shadow family-poppins-medium">
                              No posts found
                            </div>
                          )}

                          {isFetchingMyPosts && (
                            <Loader />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-12 col-md-12 order-xl-2 order-3">
                      <div className="card aside-card lS-card-shadow lS-card-radius border-0 mb-md-5 mb-4">
                        <div className="d-flex align-items-center justify-content-between p-4 border-bottom ls-border-color">
                          <h4 className="family-poppins-semibold m-0">Poll of the Week</h4>
                          <div className="news-nav">
                            <button id="poll-nav-prev" className="btn p-0 rounded-circle border-0 swiper-nav-prev mr-2 mr-md-3" onClick={() => {this.pollsUpdateSlide('prev')}}>
                              <i className="fas fa-chevron-left" />
                            </button>
                            <button id="poll-nav-next" className="btn p-0 rounded-circle border-0 swiper-nav-next" onClick={() => {this.pollsUpdateSlide('next')}}>
                              <i className="fas fa-chevron-right" />
                            </button>
                          </div>
                        </div>
                        <div className="card-body px-4 aside-poll-card">
                          {!isFetchingPollingList && pollList && pollList.length === 0 && (
                            <div className="text-center">No Poll found</div>
                          )}

                          {isFetchingPollingList && (
                            <Loader />
                          )}
                          {!isFetchingPollingList && pollList && pollList.length > 0 && (
                            <Swiper getSwiper={(swiper) => this.setState({ swiper })} {...pollsSliderParams}>
                              {pollList.map((pollObj, index) => (
                                <div>
                                  <Poll 
                                    question={pollObj.attributes.question}
                                    options={pollObj.option}
                                    onAnswer={(option, optionIndex) => {this.applyUserVote(option, index, optionIndex)}}
                                    isAlreadyAnswer={pollObj.attributes.has_already_voted || false}
                                  />
                                </div>
                              ))}
                            </Swiper>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <UserFooter />

        {isVisiblePostForums && (
          <PostForums 
            isVisible={isVisiblePostForums}
            onClose={isReload => {
              this.setState({
                isVisiblePostForums: false,
              });
              if(isReload) {window.location.reload();}
            }}
            mode="add"
          />
        )}
      </>
    );
  }
}

/**
 *  Define component PropTypes
 */
LSForum.propTypes = {
  categories: array.isRequired,
  posts: array.isRequired,
  myPosts: array.isRequired,
  getCategories: func.isRequired,
  getPolls: func.isRequired,
  getPosts:func.isRequired,
  isFetchingCategories: bool.isRequired,
  isFetchingPosts: bool.isRequired,
  isFetchingMyPosts: bool.isRequired,
  isFetchingPollingList: bool.isRequired,
  page: number.isRequired,
  pollList: array.isRequired,
  totalPages: number.isRequired,
};

/**
 *  Map redux state to component props
 */
const mapStateToProps = createStructuredSelector({
  categories: getCategoriesList(),
  posts: getPostsList(),
  myPosts: getMyPostsList(),
  isFetchingCategories: getIsFetchingCategories(),
  isFetchingPosts:getIsFetchingPosts(),
  isFetchingMyPosts: getIsFetchingMyPosts(),
  isFetchingPollingList: getIsFetchingPollingList(),
  page: getPage(),
  pollList: getPollList(),
  totalPages: getTotalPages()
});

export default connect(
  mapStateToProps,
  {
    getCategories,
    getPolls,
    getPosts,
    getMyPosts,
    applyFilters,
    resetFilters,
    applyPagination,
    addVoteToPoll,
  }
)(withRouter(LSForum));
