import Index from "views/Index.jsx";
import Profile from "views/userProfile";
import UpdatePersonalInfo from "views/updatePersonalInfo";
import Jobs from "views/jobs";
import JobPost from "views/jobPost";
import TrainingPost from "views/trainingPost";
import JobDetail from "views/jobDetail";
import Register from "views/signup/";
import Login from "views/login";
import EmailVerify from "views/emailVerify";
import Trainings from "views/trainings";
import TrainingDetail from "views/trainingDetail"
import Resources from "views/resources";
import categoryListing from "views/competencies/categoryListing";
// import AssessorResource from "views/assessorResource";
// import PostResource from "views/postResource";
import SingleResources from "views/singleResource";
import SingleResourcesIndex from "views/singleResourceIndexes";
import ForgotPassword from "views/forgotPassword";
import ResetPassword from "views/resetPassword";
import MyPcn from "views/myPcn/pcnList";
import NetworkDetailsComponent from "./views/networkDetails";
// import Pathway from "views/pathWay";
// import SupervisorResource from "./views/supervisorResource";
import news from "./views/news";
// import newsPost from "./views/newsPost";
// import PostInvoice from "./views/postInvoice";
// import MyInvoiceList from "./views/myInvoiceList";
// import ClientInvoiceList from "./views/clientInvoiceList";
// import ClientInvoiceById from "./views/clientInvoiceById";
import placementPost from "./views/placementPost";
import placements from "./views/placements";
import PlacementDetail from "views/placementDetail"
import JobApplicants from "./views/jobApplicants";
import TrainingApplicants from "./views/trainingApplicants";
import ForumsChat from "./views/forums";
import PublicPassport from "./views/publicPassport";
import Reports from "./views/reports";
import MyBooking from "./views/myBooking";
// import PreviousTrainingApplied from "./views/previousTrainingApplied";
import PlacementApplicants from "./views/placementApplicants";
import EventsListing from "./views/events";
import PostEvents from './views/postEvents';
import EventDetail from './views/eventDetail';
import EventApplicants from './views/eventApplicants';
import Revalidation from './views/revalidation/revalidation';
import cpdLog from "./views/revalidation/cpdLog";
import feedbackLog from "./views/revalidation/feedbackLog";
import practiceHoursLog from "./views/revalidation/practiceHoursLog";
import refAccForm from "./views/revalidation/refAccForm";
import refDiscForm from "./views/revalidation/refDiscForm";
import confForm from "./views/revalidation/confForm";
import postWellBeing from "./views/WellBeing/postWellBeing";
import ListWellBeing from "./views/WellBeing/listWellBeing"
import WellBeingDetail from "./views/WellBeing/singleWellBeing";
// import WellBeingApplicants from "./views/WellBeing/wellBeingApplicants";
import postPolicyDoc from "./views/policyDocs/postPolicyDocs";
import policyDocs from "./views/policyDocs/listPolicyDocs";
import placementMentors from "./views/placementMentors";
import supervisorUserListing from "./views/competencies/supervisorUserListing";
import supervisorBooklets from "./views/competencies/supervisorBooklets";
import supervisorBookletIndex from "./views/competencies/supervisorBookletIndex";
import superVisorBookletDetails from "./views/competencies/supervisorBookletDetails";

import assessorUserListing from "./views/competencies/assessorUserListing";
import assessorBooklets from "./views/competencies/assessorBooklets";
import assessorBookletIndex from "./views/competencies/assessorBookletIndex";
import assessorBookletDetails from "./views/competencies/assessorBookletDetails";
import lsForums from "./views/ls-forums";
import lsForumsPost from "./views/ls-forums/postForums";
import lsForumsPostDetails from "./views/ls-forums/postDetails";
import Teams from "./views/teams";
import TrainingAnalysis from "./views/training-analysis";
import TrainingAnalysisReport from "./views/trainingAnalysisReport";
import newsDetail from "./views/newsDetail";
import PathWay from './views/pathway';
import PollingPage from './views/polling';
import Appraisals from './views/appraisals';
import PostAppraisals from './views/appraisals/postAppraisals';

// // Admin routes
import AdminUserListing from "./views/adminUserListing";
import AdminAddUser from "./views/adminAddUser";


var routes = [
  {
    path: "/index",
    name: "Home",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/user",
  },
  {
    path: "/user-profile",
    name: "My Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/user",
  },
  {
    path: "/update-practice-info/:id",
    name: "Update practice Info",
    icon: "ni ni-single-02 text-yellow",
    component: UpdatePersonalInfo,
    layout: "/user",
  },
  {
    path: "/trainings",
    name: "Training",
    icon: "ni ni-single-02 text-yellow",
    component: Trainings,
    layout: "/user",
  },
  {
    path: "/trainings/:id",
    name: "Training Detail",
    icon: "ni ni-single-02 text-yellow",
    component: TrainingDetail,
    layout: "/user",
  },
  {
    path: "/training-post",
    name: "Post Training",
    icon: "ni ni-single-02 text-yellow",
    component: TrainingPost,
    layout: "/user",
  },
  {
    path: "/training-post/:id",
    name: "Edit Post",
    icon: "ni ni-single-02 text-yellow",
    component: TrainingPost,
    layout: "/user",
  },
  {
    path: "/resources/category",
    name: "Competencies",
    icon: "ni ni-single-02 text-yellow",
    component: categoryListing,
    layout: "/user",
  },
  {
    path: "/resources/category/:categoryId",
    name: "Competencies",
    icon: "ni ni-single-02 text-yellow",
    component: Resources,
    layout: "/user",
  },
  // {
  //   path: "/assessor-resource",
  //   name: "Assessor Resource",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: AssessorResource,
  //   layout: "/user",
  // },
  // {
  //   path: "/supervisor-resource",
  //   name: "Supervisor Resource",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: SupervisorResource,
  //   layout: "/user",
  // },
  {
    path: "/resources/:bookLetID/index/:indexID",
    name: "Single Resources Index",
    icon: "ni ni-single-02 text-yellow",
    component: SingleResources,
    layout: "/user",
  },
  {
    path: "/resources/:bookLetID",
    name: "Single Resources",
    icon: "ni ni-single-02 text-yellow",
    component: SingleResourcesIndex,
    layout: "/user",
  },
  // {
  //   path: "/post-resource",
  //   name: "Booklets",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: PostResource,
  //   layout: "/user",
  // },
  {
    path: "/my-pcn",
    name: "Networks",
    icon: "ni ni-single-02 text-yellow",
    component: MyPcn,
    layout: "/user",
  },
  {
    path: "/network-details/:id",
    name: "Networks Details",
    icon: "ni ni-single-02 text-yellow",
    component: NetworkDetailsComponent,
    layout: "/user",
  },
  // {
  //   path: "/pathway",
  //   name: "Pathway",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Pathway,
  //   layout: "/user",
  // },
  {
    path: "/forums",
    name: "Chat",
    icon: "ni ni-single-02 text-yellow",
    component: ForumsChat,
    layout: "/user",
  },
  {
    path: "/placements",
    name: "Placements",
    icon: "ni ni-single-02 text-yellow",
    component: placements,
    layout: "/user",
  },
  {
    path: "/placements/:id",
    name: "Placement Details",
    icon: "ni ni-single-02 text-yellow",
    component: PlacementDetail,
    layout: "/user",
  },
  {
    path: "/placement-post",
    name: "Post Placement",
    icon: "ni ni-single-02 text-yellow",
    component: placementPost,
    layout: "/user",
  },
  {
    path: "/placement-post/:id",
    name: "Edit Placement",
    icon: "ni ni-single-02 text-yellow",
    component: placementPost,
    layout: "/user",
  },
  {
    path: "/placement-applicants/:placementId",
    name: "Placement Applicants",
    icon: "ni ni-single-02 text-yellow",
    component: PlacementApplicants,
    layout: "/user",
  },
  {
    path: "/placement-mentors/:placementId",
    name: "Placement Mentors",
    icon: "ni ni-single-02 text-yellow",
    component: placementMentors,
    layout: "/user",
  },
  {
    path: "/news",
    name: "News",
    icon: "ni ni-single-02 text-yellow",
    component: news,
    layout: "/user",
  },
  {
    path: "/news/:newsID",
    name: "NewsDetails",
    icon: "ni ni-single-02 text-yellow",
    component: newsDetail,
    layout: "/user",
  },
  // {
  //   path: "/post-news",
  //   name: "Post News",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: newsPost,
  //   layout: "/user",
  // },
  // {
  //   path: "/post-news/:newsId",
  //   name: "Edit News",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: newsPost,
  //   layout: "/user",
  // },
  {
    path: "/jobs",
    name: "Jobs",
    icon: "ni ni-single-02 text-yellow",
    component: Jobs,
    layout: "/user",
  },
  {
    path: "/jobs/:jobID",
    name: "JobsDetails",
    icon: "ni ni-single-02 text-yellow",
    component: JobDetail,
    layout: "/user",
  },
  {
    path: "/job-applicants/:jobId",
    name: "Job Applicants",
    icon: "ni ni-single-02 text-yellow",
    component: JobApplicants,
    layout: "/user",
  },
  {
    path: "/job-post",
    name: "Post Job",
    icon: "ni ni-single-02 text-yellow",
    component: JobPost,
    layout: "/user",
  },
  {
    path: "/job-post/:id",
    name: "Edit Post",
    icon: "ni ni-single-02 text-yellow",
    component: JobPost,
    layout: "/user",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    icon: "ni ni-key-25 text-info",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/reset-password/:token",
    name: "Reset Password",
    icon: "ni ni-key-25 text-info",
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/email-verify/:token",
    name: "Email Verify",
    icon: "ni ni-key-25 text-info",
    component: EmailVerify,
    layout: "/auth",
  },
  // {
  //   path: "/post-invoice",
  //   name: "Post Invoice",
  //   icon: "ni ni-key-25 text-info",
  //   component: PostInvoice,
  //   layout: "/user",
  // },
  // {
  //   path: "/my-invoices",
  //   name: "Invoices",
  //   icon: "ni ni-key-25 text-info",
  //   component: MyInvoiceList,
  //   layout: "/user",
  // },
  {
    path: "/reports",
    name: "Reports",
    icon: "ni ni-key-25 text-info",
    component: Reports,
    layout: "/user",
  },
  {
    path: "/pathway",
    name: "Pathways",
    icon: "ni ni-key-25 text-info",
    component: PathWay,
    layout: "/user",
  },
  // {
  //   path: "/client-invoices",
  //   name: "Invoice",
  //   icon: "ni ni-key-25 text-info",
  //   component: ClientInvoiceList,
  //   layout: "/user",
  // },
  // {
  //   path: "/client-invoices/:id",
  //   name: "Add Invoices",
  //   icon: "ni ni-key-25 text-info",
  //   component: ClientInvoiceById,
  //   layout: "/user",
  // },
  {
    path: "/events",
    name: "Events",
    icon: "ni ni-key-25 text-info",
    component: EventsListing,
    layout: "/user",
  },
  {
    path: "/events/:id",
    name: "EventDetail",
    icon: "ni ni-key-25 text-info",
    component: EventDetail,
    layout: "/user",
  },
  {
    path: "/post-event",
    name: "Post Event",
    icon: "ni ni-key-25 text-info",
    component: PostEvents,
    layout: "/user",
  },
  {
    path: "/post-event/:id",
    name: "Edit Event",
    icon: "ni ni-key-25 text-info",
    component: PostEvents,
    layout: "/user",
  },
  {
    path: "/appraisals",
    name: "Appraisals",
    icon: "ni ni-key-25 text-info",
    component: Appraisals,
    layout: "/user",
  },
  {
    path: "/appraisals/supervisor",
    name: "Appraisals",
    icon: "ni ni-key-25 text-info",
    component: Appraisals,
    layout: "/user",
  },
  {
    path: "/post-appraisals",
    name: "Post Appraisals",
    icon: "ni ni-key-25 text-info",
    component: PostAppraisals,
    layout: "/user",
  },
  {
    path: "/post-appraisals/:id",
    name: "Post Appraisals",
    icon: "ni ni-key-25 text-info",
    component: PostAppraisals,
    layout: "/user",
  },
  {
    path: "/post-appraisals/:id/supervisor",
    name: "Post Appraisals",
    icon: "ni ni-key-25 text-info",
    component: PostAppraisals,
    layout: "/user",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/register/:type",
    name: "Register",
    icon: "ni ni-single-02 text-yellow",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-single-02 text-yellow",
    component: AdminUserListing,
    layout: "/user",
  },
  {
    path: "/add-user",
    name: "Add User",
    icon: "ni ni-single-02 text-yellow",
    component: AdminAddUser,
    layout: "/user",
  },
  {
    path: "/passport/:token",
    name: "Passpot Token",
    icon: "ni ni-circle-08 text-pink",
    component: PublicPassport,
    layout: "/public",
  },
  {
    path: "/training-applicants/:trainingId",
    name: "Training Applicants",
    icon: "ni ni-single-02 text-yellow",
    component: TrainingApplicants,
    layout: "/user",
  },
  {
    path: '/event-applicants/:eventId',
    name: "Event Applicants",
    icon: "ni ni-single-02 text-yellow",
    component: EventApplicants,
    layout: "/user",
  },
  {
    path: "/training-applied",
    name: "Training Applied",
    icon: "ni ni-single-02 text-yellow",
    component: MyBooking,
    layout: "/user",
  },
  {
    path: "/polling",
    name: "Poll",
    icon: "ni ni-single-02 text-yellow",
    component: PollingPage,
    layout: "/user",
  },
  // {
  //   path: "/previous-booking-training",
  //   name: "Previous Booking",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: PreviousTrainingApplied,
  //   layout: "/user",
  // },
  {
    path: "/revalidation",
    name: "Revalidation",
    icon: "ni ni-single-02 text-yellow",
    component: Revalidation,
    layout: "/user",
  },
  {
    path: "/revalidation/cpd-log",
    name: "Revalidation CPD Log",
    icon: "ni ni-single-02 text-yellow",
    component: cpdLog,
    layout: "/user",
  },
  {
    path: "/revalidation/feedback-log",
    name: "Revalidation Feedback Log",
    icon: "ni ni-single-02 text-yellow",
    component: feedbackLog,
    layout: "/user",
  },
  {
    path: "/revalidation/ref-acc-form",
    name: "Revalidation Ref Acc Form",
    icon: "ni ni-single-02 text-yellow",
    component: refAccForm,
    layout: "/user",
  },
  {
    path: "/revalidation/ref-disc-form",
    name: "Revalidation Ref Disc Form",
    icon: "ni ni-single-02 text-yellow",
    component: refDiscForm,
    layout: "/user",
  },
  {
    path: "/revalidation/practice-hours-log",
    name: "Revalidation Practice Hours Log",
    icon: "ni ni-single-02 text-yellow",
    component: practiceHoursLog,
    layout: "/user",
  },
  {
    path: "/revalidation/confirmation-form",
    name: "Revalidation Confirmation Form",
    icon: "ni ni-single-02 text-yellow",
    component: confForm,
    layout: "/user",
  },
  {
    path: "/post-well-being",
    name: "Post WellBeing",
    icon: "ni ni-single-02 text-yellow",
    component: postWellBeing,
    layout: "/user",
  },
  {
    path: "/post-well-being/:id",
    name: "Edit WellBeing",
    icon: "ni ni-single-02 text-yellow",
    component: postWellBeing,
    layout: "/user",
  },
  {
    path: "/well-being",
    name: "Well-Being",
    icon: "ni ni-single-02 text-yellow",
    component: ListWellBeing,
    layout: "/user",
  },
  {
    path: "/well-being/:id",
    name: "WellBeing Detail",
    icon: "ni ni-single-02 text-yellow",
    component: WellBeingDetail,
    layout: "/user",
  },
  // {
  //   path: "/well-being-applicants/:trainingId",
  //   name: "WellBeing Applicants",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: WellBeingApplicants,
  //   layout: "/user",
  // },
  {
    path: "/post-guidelines",
    name: "Post GuideLine",
    icon: "ni ni-single-02 text-yellow",
    component: postPolicyDoc,
    layout: "/user",
  },
  {
    path: "/post-guidelines/:id",
    name: "Edit GuideLine",
    icon: "ni ni-single-02 text-yellow",
    component: postPolicyDoc,
    layout: "/user",
  },
  {
    path: "/guidelines",
    name: "Guidelines",
    icon: "ni ni-single-02 text-yellow",
    component: policyDocs,
    layout: "/user",
  },
  {
    path: "/supervisor/user-listing",
    name: "Supervisor UserListing",
    icon: "ni ni-single-02 text-yellow",
    component: supervisorUserListing,
    layout: "/user",
  },
  {
    path: "/supervisor/user-listing/:id",
    name: "Supervisor Booklets Listing",
    icon: "ni ni-single-02 text-yellow",
    component: supervisorBooklets,
    layout: "/user",
  },
  {
    path: "/supervisor/user-listing/:userId/booklet/:bookletId",
    name: "Supervisor Booklet Index",
    icon: "ni ni-single-02 text-yellow",
    component: supervisorBookletIndex,
    layout: "/user",
  },
  {
    path: '/supervisor/user-listing/:userId/booklet/:bookletId/index/:indexId',
    name: "Supervisor Booklet Details",
    icon: "ni ni-single-02 text-yellow",
    component: superVisorBookletDetails,
    layout: "/user",
  },
  {
    path: "/assessor/user-listing",
    name: "assessor UserListing",
    icon: "ni ni-single-02 text-yellow",
    component: assessorUserListing,
    layout: "/user",
  },
  {
    path: "/assessor/user-listing/:id",
    name: "assessor Booklets Listing",
    icon: "ni ni-single-02 text-yellow",
    component: assessorBooklets,
    layout: "/user",
  },
  {
    path: "/assessor/user-listing/:userId/booklet/:bookletId",
    name: "assessor Booklet Index",
    icon: "ni ni-single-02 text-yellow",
    component: assessorBookletIndex,
    layout: "/user",
  },
  {
    path: '/assessor/user-listing/:userId/booklet/:bookletId/index/:indexId',
    name: "assessor Booklet Details",
    icon: "ni ni-single-02 text-yellow",
    component: assessorBookletDetails,
    layout: "/user",
  },
  {
    path: '/ls-forums',
    name: "Forums",
    icon: "ni ni-single-02 text-yellow",
    component: lsForums,
    layout: "/user",
  },
  {
    path: '/ls-forums/cat/:catId',
    name: "Forums",
    icon: "ni ni-single-02 text-yellow",
    component: lsForums,
    layout: "/user",
  },
  {
    path: '/ls-forums/question',
    name: "Forums Post",
    icon: "ni ni-single-02 text-yellow",
    component: lsForumsPost,
    layout: "/user",
  },
  {
    path: '/ls-forums/question/:postId',
    name: "Forums Post Edit",
    icon: "ni ni-single-02 text-yellow",
    component: lsForumsPost,
    layout: "/user",
  },
  {
    path: '/ls-forums/post/:postId',
    name: "Forums Post Details",
    icon: "ni ni-single-02 text-yellow",
    component: lsForumsPostDetails,
    layout: "/user",
  },
  {
    path: '/teams',
    name: "Team",
    icon: "ni ni-single-02 text-yellow",
    component: Teams,
    layout: "/user",
  },
  {
    path: '/training-analysis',
    name: "Training Analysis",
    icon: "ni ni-single-02 text-yellow",
    component: TrainingAnalysis,
    layout: "/user",
  },
  {
    path: '/training-analysis-report',
    name: "Training Analysis",
    icon: "ni ni-single-02 text-yellow",
    component: TrainingAnalysisReport,
    layout: "/user",
  },
];
export default routes;